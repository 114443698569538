import React from 'react';
import { dhlBlack, dhlRedButton } from '../../styles/commonStyles';

const Title = () => {
  return (
    <div
      style={{
        color: dhlBlack,
        fontWeight: 'bold',
        fontStyle: 'italic',
        fontSize: 26,
      }}
    >
      <div style={{ color: dhlRedButton, display: 'inline-block' }}>SMART</div>
      <div style={{ display: 'inline-block' }}>OPERATIONS</div>
    </div>
  );
};

export default Title;
