import React from 'react';
import { dhlRedButton } from '../../styles/commonStyles';

const ErrorMessage = (props) => {
  return (
    <div
      style={{
        color: dhlRedButton,
        marginTop: 20,
        fontSize: 17,
        fontWeight: 'bold',
        textAlign: 'center',
      }}
    >
      {props.message}
    </div>
  );
};

export default ErrorMessage;
