import { childAppURLToAppKeyMapping } from './app_mapping';

export const parseBRFromURL = () => {
  const url = window.location.toString();
  return url.substring(url.lastIndexOf('parent') + 7).split('/')[0];
};

export const parseAppLabelFromURL = () => {
  const url = window.location.pathname;
  const applicationLabelRegexp = /\/(\w+)\/?/;
  const urlParts = url.match(applicationLabelRegexp);
  const url_app_label = urlParts ? urlParts[1] : undefined;
  return childAppURLToAppKeyMapping[url_app_label || ''] || url_app_label;
};
