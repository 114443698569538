export const dhlBasicOrange = '#FFCC00';
export const dhlLightOrange = '#FFEEAD';
export const dhlBlack = '#323232';
export const dhlWhiteBasic = '#ffffff';
export const dhlSeparate = '#f2f2f2';
export const dhlRedButton = '#D40511';
export const dhlBlue = '#069BDE';
export const dhlMenu = '#fff0b3';
export const dhlGrey = '#989898';
export const buttonActiveOpacity = 0.8;
export const textColor = '#000';
export const buttonIconColor = '#000';
export const buttonSecondaryUnderlayColor = '#555';
export const fontSizeMedium = 18;
