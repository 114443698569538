import {
  KEY_API_GW_URL_CLEAN,
  KEY_CLIENT_ID,
  KEY_CLIENT_SECRET,
  REQUEST_TIMEOUT,
  REST_RESPONSE_CONNECTION_ERROR,
} from '../configs/active-env';
import {doFetch} from '../utils/fetch';
import {loginMetricsAttributes} from '../utils/metrics';
import Base64 from '../utils/Base64';
import {loadMswValue} from '../database/webStorage';

export const grantTokensUsingLoginAndPassword = async (login, password) => {
  const metricsAttributes = await loginMetricsAttributes();
  let params = {
    username: login,
    password: password,
    ...metricsAttributes,
  };
  const gateway = await loadMswValue(KEY_API_GW_URL_CLEAN);
  const url = `${gateway}/smartops/um/oauth/token?grant_type=password`;
  const response = await postParamsWithJSONResponseToUM(url, params);
  return response;
};

const postParamsWithJSONResponseToUM = async (url, params) => {
  const encodedParams = encodeParameters(params);
  let response;
  try {
    response = await postDataToUMWithTimeout(url, encodedParams);
  } catch (error) {
    console.log(handleFetchException(error));
  }
  return response;
};

const encodeParameters = (params) => {
  let formBody = [];
  for (const property in params) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');
  return formBody;
};

const postDataToUMWithTimeout = async (url, formBody) => {
  try {
    const posts = await postDataToUM(url, formBody);
    if (posts) {
      return posts;
    }
  } catch (e) {
    console.log('Fetch error', e);
    throw new Error(
      'Connection timeout, make sure you are connected to the network.'
    );
  }
};

const postDataToUM = async (url, formBody) => {
  const OAUTH_SECRET_USERNAME = await loadMswValue(KEY_CLIENT_ID);
  const OAUTH_SECRET_PASSWORD = await loadMswValue(KEY_CLIENT_SECRET);
  return doFetch(url, {
    timeout: REQUEST_TIMEOUT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + Base64.btoa(OAUTH_SECRET_USERNAME + ':' + OAUTH_SECRET_PASSWORD),
    },
    body: formBody,
  });
};

const handleFetchException = error => {
  return {result: REST_RESPONSE_CONNECTION_ERROR, body: error.message};
};
