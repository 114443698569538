import React from 'react';

const Footer = (props) => {
  return (
    <div
      style={{
        marginTop: 20,
        width: '90%',
        textAlign: 'right',
        justifyContent: 'flex-end',
      }}
    >
      <div
        style={{ textDecoration: 'underline', color: '#069BDE', fontSize: 20 }}
      >
        <a style={{ cursor: 'pointer' }} onClick={props.onLogout}>{props.logoutText}</a>
      </div>
    </div>
  );
};

export default Footer;
