//DoFetch params
export const REQUEST_TIMEOUT = 6000;

//handle fetch exceptions
export const REST_RESPONSE_CONNECTION_ERROR = 'CONNECTION_ERROR';

//keys
export const PARENT_LOGGED_USER_DATA = 'PARENT_LOGGED_USER_DATA';
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const IS_LOCKED = 'locked';
export const LOCKSCREEN_SHOWUP = 'lockscreen_showup';
export const USERNAME = 'username';
export const LAST_USER_ACTIVITY = 'LAST_USER_ACTIVITY';
export const LAST_LOGIN_TIME_ISO_STRING = 'last_login';
export const KEY_BUSINESS_RELEASE = 'business_release';
export const KEY_API_GW_URL_CLEAN = 'apiGwUrl_clean';
export const KEY_CLIENT_ID = 'client_id';
export const KEY_CLIENT_SECRET = 'client_secret';
export const KEY_PARENT_VERSION = 'parent_app_version';
export const KEY_METRICS_EVENT_SESSION_ID = 'MetricsEventSessionIdValue';
export const KEY_USER_GROUP_ID = 'MetricsUserGroupID';
export const KEY_GROUPS_APPS_FACILITIES_MAP = 'groups_apps_facilities_map';
export const KEY_GROUPS_APPS_COUNTRIES_MAP = 'groups_apps_countries_map';
