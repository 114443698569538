import React from 'react';

const Button = (props) => {
  return (
    <div
      style={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor: '#D40511',
        width: '100%',
        borderRadius: 4,
        cursor: 'pointer',
        height: 40,
      }}
      onClick={props.onClick}
    >
      <a
        style={{
          fontSize: 16,
          alignSelf: 'center',
          display: 'inline-block',
          verticalAlign: 'middle',
          fontWeight: 600,
        }}
      >
        {props.title}
      </a>
    </div>
  );
};

export default Button;
