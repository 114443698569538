import React from 'react';
import './LoggedMessage.css';

const LoggedMessage = (props) => {
  return (
    <div className='text'>
      {props.message} {props.username}
    </div>
  );
};

export default LoggedMessage;
