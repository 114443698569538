export const doFetch = fetchWithTimeout;

/**
 * Custom implementation of fetch with timeout since 'react-native-fetch-polyfill'
 * does not honor the timeout that we specify,
 * also see: https://github.com/robinpowered/react-native-fetch-polyfill/issues/7
 * @param url
 * @param options = {...otherOptions, timeout?: milliseconds}
 * @returns {Promise<unknown>}
 */
async function fetchWithTimeout(url, options) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), options.timeout || 10000)),
  ]);
}
