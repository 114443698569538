import {loadMswValue} from '../database/webStorage';
import {KEY_GROUPS_APPS_COUNTRIES_MAP, KEY_GROUPS_APPS_FACILITIES_MAP} from '../configs/active-env';
import {parseAppLabelFromURL} from './url';
import {getUserGroupId} from './metrics';

type ArrayPerId = {
  [index: string]: string[];
};

type AttributeValuePerAppPerGroup = {
  [index: string]: ArrayPerId;
};

export const getCountriesByGroupAndApp = async (groupId: string, appLabel: string) => {
  const translateMapString = await loadMswValue(KEY_GROUPS_APPS_COUNTRIES_MAP);
  const translateMap: AttributeValuePerAppPerGroup = JSON.parse(translateMapString || '{}');
  const appItem = translateMap[groupId];
  return appItem ? appItem[appLabel] || [] : [];
};

export const getFacilitiesByGroupAndApp = async (groupId: string, appLabel: string) => {
  const translateMapString = await loadMswValue(KEY_GROUPS_APPS_FACILITIES_MAP);
  const translateMap: AttributeValuePerAppPerGroup = JSON.parse(translateMapString || '{}');
  const appItem = translateMap[groupId];
  return appItem ? appItem[appLabel] || [] : [];
};

export const updateMetricAttributesWithCountryAndFacility = async (attributes?: {[key: string]: string | string[]}) => {
  const app_label = parseAppLabelFromURL();
  if (app_label) {
    const usergroup_id = await getUserGroupId();
    const country = await getCountriesByGroupAndApp(usergroup_id || '-1', app_label);
    const facility = await getFacilitiesByGroupAndApp(usergroup_id || '-1', app_label);
    if (!attributes) {
      attributes = {};
    }
    if (country && country.length !== 0) {
      attributes.country = country.toString();
    }
    if (facility && facility.length !== 0) {
      attributes.facility = facility.toString();
    }
  }
  return attributes;
};
