import React from 'react';
import './TextInput.css';

const TextInput = props => {
  const _handleKeyDown = e => {
    if (e.key === 'Enter') {
      console.log('onEnter');
      props.unlockHandler(e);
    }
  };

  return (
    <div style={{marginBottom: 10}}>
      <form>
        <input
          className="code-input"
          type="password"
          autoComplete="current-password"
          placeholder={props.placeholder}
          value={props.inputValue}
          onChange={props.onValueChange}
          onKeyDown={_handleKeyDown}
        />
      </form>
    </div>
  );
};

export default TextInput;
